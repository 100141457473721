<template>
  <div class="question-container">
    <!-- Header and Progress Bar -->
    <div class="progress-bar-container">
      <div class="progress-bar">
        <div class="progress" :style="{ width: progressBarWidth + '%' }"></div>
      </div>

      <!-- Timer or Start Button -->
      <div class="timer-container">
        <button v-if="!timerStarted" @click="startTimer" class="start-timer-button">
          ⏳ {{ minutes }}:{{ seconds < 10 ? '0' + seconds : seconds }} Start Timer
        </button>
        <div v-else class="timer">
          ⏳ {{ minutes }}:{{ seconds < 10 ? '0' + seconds : seconds }}
        </div>
      </div>
    </div>

    <!-- Task Description -->
    <div class="task-header">
      <div class="top-layer">
        <div class="points-section">
          <h3>Challenge 5</h3>
          <img src="../../../../assets/Lightning Bolt.png" alt="Lightning Bolt" class="lightning-bolt">
          <p class="points">5 points</p>
        </div>
        <div class="button-container">
          <!-- <button class="hint-button" @click="showHintModal = true">Hint?</button> -->
        </div>
      </div>
      <div>
        <p>
          Ben decides he wants to get another loan in the future, so he would like to improve his
          <span class="hoverable-term"
                @mouseover="(event) => showHoverModal('Credit Rating', 'A score that everyone has, that tells banks how good you are at paying back money. If you have a high score, banks think you’re good at paying back and are more likely to lend you money.', event)"
                @mouseleave="hideHoverModal">
            <strong>credit rating</strong>
          </span>.
        </p>
        <img src="../../../../assets/moneyhandshake.png" alt="Task 5 Image" class="task-image">
      </div>
    </div>

        <!-- Glossary Sidebar -->
        <div v-if="showGlossary" class="glossary-sidebar">
      <div class="glossary-header">
        <h2>{{ glossaryTitle }}</h2>
        <button class="close-button" @click="showGlossary = false">X</button>
      </div>
      <div class="glossary-content">
        <p>{{ glossaryContent }}</p>
      </div>
    </div>

    <!-- Hint Modal -->
    <div v-if="showHintModal" class="hint-modal-overlay">
      <div class="hint-modal">
        <h3>Hint</h3>
        <p>Net worth = Total Assets – Total Liabilities</p>
        <button @click="showHintModal = false" class="close-modal-button">Close</button>
      </div>
    </div>

    <!-- Question Section -->
    <div class="question-section">
      <p class="question-section">
        Which of the following things improve your
        <span class="hoverable-term"
              @mouseover="(event) => showHoverModal('Credit Rating', 'A score that everyone has, that tells banks how good you are at paying back money. If you have a high score, banks think you’re good at paying back and are more likely to lend you money.', event)"
              @mouseleave="hideHoverModal">
          <strong>credit rating</strong>
        </span>?
      </p>
    </div>

    <!-- Conditionally render either the answer options or the results section -->
    <div v-if="!showResults">
      <!-- Answer Options -->
      <div class="answer-options">
        <div v-for="(option, index) in answerOptions" :key="index" class="answer-option">
          <p>{{ option }}</p>
        </div>
      </div>

      <!-- Teams Answer Selection -->
      <div class="teams-selection">
        <div v-for="(team, teamIndex) in teams" :key="teamIndex" class="team-selection">
          <h4>{{ team.name }}</h4>
          <div class="team-options">
            <button 
              v-for="(option, index) in answerOptions" 
              :key="index" 
              :class="{'selected': teamAnswers[teamIndex].includes(index)}" 
              @click="toggleTeamAnswer(teamIndex, index)">
              {{ String.fromCharCode(65 + index) }} <!-- A, B, C, D, ... -->
            </button>
          </div>
        </div>
      </div>

      <!-- Submit Button -->
      <button class="submit-button" @click="submitAnswers">Submit</button>
    </div>

    <!-- Results Section -->
    <div v-else class="results-section">
      <!-- Updated answer options with correct/incorrect indicators -->
      <div class="answer-options">
        <div v-for="(option, index) in answerOptions" :key="index" :class="{'correct': correctAnswers.includes(index), 'incorrect': !correctAnswers.includes(index)}" class="answer-option">
          <p>{{ option }}</p>
        </div>
      </div>

      <!-- Team Results -->
      <div class="teams-results">
        <div v-for="(team, index) in teams" :key="index" class="team-result">
          <h4>{{ team.name }}</h4>
          <div v-for="(answer, answerIndex) in teamAnswers[index]" :key="answerIndex" :class="{'correct': correctAnswers.includes(answer), 'incorrect': !correctAnswers.includes(answer)}">
            {{ String.fromCharCode(65 + answer) }}
          </div>
          <p>Points: {{ calculateScore(index) }}</p>
        </div>
      </div>

      <!-- Next Button -->
      <button class="next-button" @click="nextQuestion">Next</button>
    </div>
        <div v-if="hoverModal.show"
        class="hover-modal"
        :style="{ top: hoverModal.y + 'px', left: hoverModal.x + 'px' }">
      <h3>{{ hoverModal.title }}</h3>
      <p>{{ hoverModal.content }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionNo5',
  props: {
    teams: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      answerOptions: [
        'A: Paying bills and payments on time',
        'B: Registering on the electoral roll',
        'C: Frequently applying for new credit',
        'D: Paying off or maintaining low levels of debt',
        'E: Keeping a bank account open for many years',
        'F: Maxing out your credit cards regularly',
        'G: Avoiding frequent credit applications',
        'H: Moving house regularly'
      ],
      correctAnswers: [0, 1, 3, 4, 6], // Correct answers: A, B, D, E, G
      teamAnswers: Array.from({ length: this.teams.length }, () => []),
      showResults: false,
      timer: 300,
      intervalId: null,
      showGlossary: false,
      glossaryTitle: '',
      glossaryContent: '',
      showHintModal: false,
      timerStarted: false, // Timer is initially not started
      hoverModal: {
        show: false,
        title: '',
        content: '',
        x: 0,
        y: 0
      },
    };
  },
  computed: {
    minutes() {
      return Math.floor(this.timer / 60);
    },
    seconds() {
      return this.timer % 60;
    },
    progressBarWidth() {
      return (this.timer / 300) * 100;
    }
  },
  methods: {
    openGlossary(term) {
      this.showGlossary = true;

      if (term === 'creditRating') {
        this.glossaryTitle = 'Credit Rating';
        this.glossaryContent = 'A score that everyone has, that tells banks how good you are at paying back money. If you have a high score, banks think you’re good at paying back and are more likely to lend you money.';
      }
    },
    showHoverModal(title, content, event) {
      this.hoverModal.show = true;
      this.hoverModal.title = title;
      this.hoverModal.content = content;
      this.hoverModal.x = event.clientX + 15; // Offset for better positioning
      this.hoverModal.y = event.clientY + 15;
    },
    hideHoverModal() {
      this.hoverModal.show = false;
      this.hoverModal.title = '';
      this.hoverModal.content = '';
    },
    toggleTeamAnswer(teamIndex, optionIndex) {
      const answers = this.teamAnswers[teamIndex];
      const answerPosition = answers.indexOf(optionIndex);

      if (answerPosition === -1) {
        this.teamAnswers[teamIndex].push(optionIndex);
      } else {
        this.teamAnswers[teamIndex].splice(answerPosition, 1);
      }
    },
    submitAnswers() {
      this.showResults = true;
      clearInterval(this.intervalId);
    },
    calculateScore(index) {
      const answers = this.teamAnswers[index] || [];
      let score = 0;
      answers.forEach((answer) => {
        if (this.correctAnswers.includes(answer)) {
          score += 1; // Add 1 point for each correct answer
        }
      });
      return score;
    },
    nextQuestion() {
      const pointsArray = this.teamAnswers.map((answers, index) => this.calculateScore(index));
      this.$emit('award-points', pointsArray); // Emit points to parent
      this.$emit('next-question'); // Move to the next question
    },
    startTimer() {
      if (!this.timerStarted) {
        this.timerStarted = true;
        this.intervalId = setInterval(() => {
          if (this.timer > 0) {
            this.timer--;
          } else {
            clearInterval(this.intervalId); // Stop the timer when time runs out
          }
        }, 1000);
      }
    }
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  }
};
</script>

<style scoped>
/* General Container */
.question-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Progress Bar */
.progress-bar-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.progress-bar {
  width: 80%;
  height: 5px;
  background-color: #e0e0e0;
  border-radius: 5px;
}

.progress {
  height: 100%;
  background-color: #4caf50;
  border-radius: 5px;
}

.clickable-term {
  color: #3b82f6;
  cursor: pointer;
  text-decoration: underline;
}

.clickable-term {
  color: #3b82f6;
  cursor: pointer;
  text-decoration: underline;
}

.clickable-term:hover {
  color: #2563eb;
}

/* Glossary Sidebar Styling */
.glossary-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 350px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding: 20px;
  overflow-y: auto;
  transition: transform 0.3s ease;
}

.glossary-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.glossary-header h2 {
  font-size: 1.5rem;
  color: #003F91;
}

.glossary-header .close-button {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #003F91;
}

.glossary-content p {
  font-size: 1rem;
  color: #555;
  margin-top: 5px;
  line-height: 1.5;
}
.glossary-button, .hint-button {
  background-color: #f0f4ff;
  border: 1px solid #e0e0e0;
  color: #003F91;
  font-weight: bold;
  font-size: 0.9rem;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.glossary-button:hover, .hint-button:hover {
  background-color: #dbe9ff;
}

.hint-button::after {
  content: '?';
  font-size: 1rem;
  margin-left: 5px;
}

.hint-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.hint-modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 500px;
  text-align: center;
}

.hint-modal h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #000;
}

.hint-modal p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #333;
}

.close-modal-button {
  background-color: #3b82f6;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.close-modal-button:hover {
  background-color: #2563eb;
}

.top-layer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.button-container {
  display: flex;
  gap: 10px;
}

/* Timer Styling */
.timer-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.timer {
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
}

.start-timer-button {
  font-size: 1rem;
  padding: 1px;
  background-color: transparent;
  color: black;
  border: 2px solid #45a04933; /* Define the border with width, style, and color */
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.start-timer-button:hover {
  background-color: #45a04933;
}

/* Task Header */
.task-header {
  margin-bottom: 20px;
}

.task-header h3 {
  font-size: 2rem;
  color: black;
}

.task-header p {
  color: #555;
  font-size: 1.4rem;
}

.task-image {
  width: 200px;
  margin-right: 20px;
}

/* Question Section */
.question-section {
  text-align: center;
  margin-top: 20px;
}

.question-section p {
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
}

.points-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

.points {
  font-size: 1.4rem;
  color: #3b82f6;
  font-weight: bold;
}

.lightning-bolt {
  width: 40px;
  height: 40px;
}


/* Answer Options Styling */
.answer-options {
  margin: 20px 0;
}

.answer-option {
  background-color: #B8CEF0;
  color: black;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  text-align: left;
  font-size: 1.4rem;
}

.correct {
  background-color: #B3E3D3 !important;
}

.incorrect {
  background-color: #FF6B6B99 !important;
}

/* Teams Answer Selection */
.teams-selection {
  margin-top: 20px;
}

.team-selection {
  margin-bottom: 20px;
}

.team-selection h4 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.team-options {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.team-options button {
  padding: 10px 15px;
  border-radius: 50%;
  border: none;
  background-color: #e0e0e0;
  font-size: 1.5rem;
  cursor: pointer;
}

.team-options button.selected {
  background-color: #007bff;
  color: white;
}

.team-options button:hover {
  background-color: #0056b3;
  color: white;
}

/* Results Section */
.results-section {
  margin-top: 20px;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  animation: fadeInUp 1s ease-out;
}

.teams-results {
  display: flex;
  justify-content: space-around;
  animation: fadeInUp 1s ease-in;
}

.team-result {
  margin-bottom: 10px;
}

.team-result h4 {
  color: black;
}

.team-result p {
  color: black;
}

.score-icon {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.score {
  font-size: 1.8rem;
  font-weight: bold;
}

/* Submit and Next Button */
.submit-button,
.next-button {
  width: 20%;
  background-color: #003F91;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 30px;
  font-size: 1.4rem;
  cursor: pointer;
  margin-top: 20px;
}

.submit-button:hover,
.next-button:hover {
  background-color: #2563eb;
}
/* Animations */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.hover-modal {
  position: fixed;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 10px;
  max-width: 300px;
  z-index: 1000;
  pointer-events: none; /* Prevent blocking other interactions */
  transition: opacity 0.3s ease-in-out;
  font-family: Arial, sans-serif;
}

.hover-modal h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 8px;
}

.hover-modal p {
  font-size: 1.2rem;
  color: #555;
  margin: 0;
}

.hoverable-term {
  color: #3b82f6;
  cursor: pointer;
  text-decoration: underline;
}

.hoverable-term:hover {
  color: #2563eb;
}

</style>
